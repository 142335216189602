import {getAppUrl} from '@renper/lib/training/utils.mjs';

export const BASE_TITLE = `RP Apps Portal`;

export const STRIPE_API_KEY =
  (RP_ENV || NODE_ENV) === 'production'
    ? 'pk_live_51HteKgE49t9KAomxxuvzXHN6N4jmjbZsT7qjihZMGt3obYustIc6p76e2P276STnGiujl6nCjSNh40rS05zW3Ynf00z58Ndb8t'
    : 'pk_test_51HteKgE49t9KAomxekh7ZK1dRwbSNn8FcTuFbRsSiZ7ftjyqReCFopA6KxaURePPtaHiMunh0CZbRx3EsEyb0sgK00iSfytJAZ';

export const COOKIE_KEY_WOOPRA = 'wooTracker';

export const CHECKOUT_COOKIE_KEY = 'rp_web_checkout';

export const TOKEN_COOKIE_KEY = 'rp_token';

export const LS_KEY_USER = 'rp-portal-auth';

export const LS_KEY_FBC = 'rp-portal-fbc';

export const LS_KEY_REFERRAL = 'rp-portal-referral';

export const REFERRAL_QUERY_PARAM_KEY = 'referralCode';

export const CLIENT_ID = 'app.rp';

export const URL_WEB = 'https://apps.rpstrength.com/';

export const URL_WEBSITE = 'https://rpstrength.com/';

export const EMAIL_APP_SUPPORT = 'app.support@rpstrength.com';

export const URL_APP_STORE_IOS = 'https://apps.apple.com/app/rp-diet/id1330041267';

export const URL_APP_STORE_ANDROID = 'https://play.google.com/store/apps/details?id=com.rp.rpdiet';

export const URL_DIET_LANDING_PAGE = 'https://rpstrength.com/pages/diet-coach-app';

export const URL_TRAINING_LANDING_PAGE = 'https://rpstrength.com/pages/hypertrophy-app';

export const URL_DIET_LANDING_PAGE_INFLUENCER =
  'https://rpstrength.com/pages/diet-coach-app-influencer';

export const URL_DIET_LANDING_PAGE_DISCOUNT =
  'https://rpstrength.com/pages/diet-coach-app-discount';

export const URL_TRAINING_LANDING_PAGE_INFLUENCER =
  'https://rpstrength.com/pages/hypertrophy-app-influencer';

export const URL_TRAINING_LANDING_PAGE_DISCOUNT =
  'https://rpstrength.com/pages/hypertrophy-app-discount';

export const URL_OPEN_APP = 'https://open.rpdiet.app/';

export const URL_TRAINING_APP = getAppUrl(RP_ENV || NODE_ENV);

export const ORDER_TYPE = {
  rp: 'rp',
  stripe: 'stripe',
};

// TODO: get rid of in favor of using from @rp/lib
export const PLATFORM = {
  rp: 'rp',
  ios: 'ios',
  android: 'android',
};

// TODO: move to @rp/lib
export const PURCHASE_TYPE = {
  accessPass: 'access_pass',
  subscription: 'subscription',
};

export let API_URL = 'https://api.rpstrength.com/';
const effectiveEnv = RP_ENV || NODE_ENV;
switch (effectiveEnv) {
  case 'production':
    API_URL = 'https://api.rpstrength.com/';
    break;
  case 'local':
    API_URL = 'http://localhost:8080/';
    break;
  case 'development':
    API_URL = 'https://api.dev.rpstrength.com/';
    break;
  default:
    API_URL = `https://api.${effectiveEnv}.rpstrength.app/`;
    break;
}
