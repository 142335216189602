import {APPS} from '@renper/lib/constants.mjs';
import propTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {
  API_URL,
  LS_KEY_FBC,
  REFERRAL_QUERY_PARAM_KEY,
  URL_DIET_LANDING_PAGE,
  URL_TRAINING_LANDING_PAGE,
} from '../constants.js';
import handle4xxErrors from '../utils/handle4xxErrors.js';
import log from '../utils/log.js';
import {recordReferralCode} from '../utils/recordReferralCode.js';
import {getHasReferralCodeChanged, sanitizeReferralCode} from '../utils/referrals.js';
import storage from '../utils/storage.js';
import {redirectWithCurrentParamsTo} from '../utils/url.js';

function getBaseRefLandingPageDiet(referralCode) {
  let destinationUrl = URL_DIET_LANDING_PAGE;

  if (new RegExp('^(RPFB|RPE)').test(referralCode)) {
    destinationUrl = URL_DIET_LANDING_PAGE;
  } else if (new RegExp('^(AD-)').test(referralCode)) {
    // We have a separate LP for targeted online advertising
    // so we have a secondary data source for conversion tracking (via Leadpages)
    // that's isolated from non-ad traffic AND so we have creative control to edit the ad LP
    // without touching the general LP
    destinationUrl = 'https://info.rpstrength.com/dietapp/';
  } else if (new RegExp('^(RPIG)').test(referralCode)) {
    destinationUrl = URL_DIET_LANDING_PAGE;
  } else if (new RegExp('^(TNRP)').test(referralCode)) {
    // AZ TODO: replace with new one once we have it
    destinationUrl = 'https://promo.rpstrength.com/trifecta/';
  }

  return destinationUrl;
}

function getBaseRefLandingPageTraining() {
  return URL_TRAINING_LANDING_PAGE;
}

function Referral({app}) {
  const {referralCode} = useParams();

  const searchParams = new URLSearchParams(window.location.search);
  const fbclid = searchParams.get('fbclid');

  if (fbclid) {
    const fbc = `fb.1.${Date.now()}.${fbclid}`;
    storage.setItem(LS_KEY_FBC, fbc);
  }

  const sanitizedReferralCode = sanitizeReferralCode(referralCode);
  const hasReferralCodeChanged = getHasReferralCodeChanged(referralCode);

  const [referralLp, setReferralLp] = useState(null);

  useEffect(() => {
    if (!referralLp && hasReferralCodeChanged) {
      recordReferralCode(referralCode).catch(log.notify);
    }
  }, [hasReferralCodeChanged, referralCode, referralLp]);

  useEffect(() => {
    if (!referralLp) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const redirect = urlSearchParams.get('redirect');
      if (redirect) {
        setReferralLp(redirect);

        return;
      }

      let landingPageUrl =
        app === APPS.diet
          ? getBaseRefLandingPageDiet(sanitizedReferralCode)
          : getBaseRefLandingPageTraining();

      (async () => {
        try {
          const refResponse = await fetch(`${API_URL}referrals/${sanitizedReferralCode}`);
          await handle4xxErrors(refResponse);
          const referral = await refResponse.json();
          let isAppLandingPage = false;
          if (app === APPS.diet && referral.lpDiet) {
            landingPageUrl = referral.lpDiet;
            isAppLandingPage = true;
          }
          if (app === APPS.training && referral.lpTraining) {
            landingPageUrl = referral.lpTraining;
            isAppLandingPage = true;
          }
          if (!isAppLandingPage && referral.lpGeneric) {
            landingPageUrl = referral.lpGeneric;
          }
        } catch (err) {
          landingPageUrl = app === APPS.diet ? URL_DIET_LANDING_PAGE : URL_TRAINING_LANDING_PAGE;
          log.notify(err);
        }

        setReferralLp(landingPageUrl);
      })();
    }
  }, [app, referralLp, sanitizedReferralCode]);

  if (!referralLp) {
    return null;
  }

  const redirectUrl = new URL(referralLp);
  if (referralCode) {
    redirectUrl.searchParams.set(REFERRAL_QUERY_PARAM_KEY, sanitizedReferralCode);
  }

  redirectWithCurrentParamsTo(redirectUrl.toString());

  return null;
}

Referral.propTypes = {
  app: propTypes.oneOf(Object.values(APPS)),
};

export default Referral;
