import Cookies from 'js-cookie';

import {API_URL, LS_KEY_USER, PLATFORM, TOKEN_COOKIE_KEY} from '../constants.js';
import handle4xxErrors from './handle4xxErrors.js';
import {clearReferralCode} from './referrals.js';
import storage from './storage.js';

const USER_ATTRIBUTES = {
  CREATED_ON_PLATFORM: 'CREATED_ON_PLATFORM',
};

const ROOT_DOMAIN = window.location.hostname.split('.').slice(1).join('.');

const IS_HOST_LOCAL = window.location.hostname === 'localhost';

function persist(user) {
  storage.setItem(LS_KEY_USER, user);

  const cookieOptions = {
    expires: 365, // days
    sameSite: 'lax',
    secure: true,
  };
  if (!IS_HOST_LOCAL) {
    cookieOptions.domain = ROOT_DOMAIN;
  }
  Cookies.set(TOKEN_COOKIE_KEY, user.token, cookieOptions);
}

function get() {
  const cookieToken = Cookies.get(TOKEN_COOKIE_KEY);
  if (!cookieToken) {
    return null;
  }
  return storage.getItem(LS_KEY_USER);
}

function getAndPersist() {
  const cookieToken = Cookies.get(TOKEN_COOKIE_KEY);
  if (!cookieToken) {
    return null;
  }
  const obj = storage.getItem(LS_KEY_USER);

  if (!obj) {
    return loginWithJwt(cookieToken);
  }

  return obj;
}

async function login(email, password) {
  const res = await fetch(`${API_URL}login`, {
    method: 'POST',
    body: JSON.stringify({email, password}),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  await handle4xxErrors(res);

  const user = await res.json();
  persist(user);

  return user;
}

async function loginWithJwt(token) {
  const res = await fetch(`${API_URL}user/me`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  await handle4xxErrors(res);

  const refreshedUser = await res.json();
  persist(refreshedUser);

  return refreshedUser;
}

async function refresh() {
  const cookieToken = Cookies.get(TOKEN_COOKIE_KEY);
  if (!cookieToken) {
    logout();

    return;
  }

  return loginWithJwt(cookieToken);
}

async function register(body) {
  const bodyWithAttributes = {
    ...body,
    attributes: {
      [USER_ATTRIBUTES.CREATED_ON_PLATFORM]: PLATFORM.rp,
    },
  };

  const res = await fetch(`${API_URL}user`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyWithAttributes),
  });

  await handle4xxErrors(res);

  const user = await res.json();
  persist(user);

  return user;
}

async function update(body) {
  const user = get();
  const res = await fetch(`${API_URL}user`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    },
    body: JSON.stringify(body),
  });

  await handle4xxErrors(res);

  const refreshedUser = await res.json();
  persist(refreshedUser);

  return refreshedUser;
}

function logout() {
  const user = get();
  if (user) {
    // It's important not to clear the referral code if logout is called when a user is already not
    // logged in. It's used to forward referral code from marketing LPs through to checkout!
    clearReferralCode();
  }

  storage.setItem(LS_KEY_USER, null);
  Cookies.remove(TOKEN_COOKIE_KEY, {domain: ROOT_DOMAIN});

  // Clear moodle login (for this to work the Moodle environment has to have sessioncookiedomain explicitly set as it defaults to the full domain).
  Cookies.remove('MoodleSession', {domain: ROOT_DOMAIN});

  // Clear website login.
  Cookies.remove('auth_token', {domain: ROOT_DOMAIN});
  Cookies.remove('_renaissance_periodization_session', {domain: ROOT_DOMAIN});
}

export default {
  ATTRIBUTES: USER_ATTRIBUTES,
  get,
  getAndPersist,
  login,
  loginWithJwt,
  register,
  update,
  persist,
  refresh,
  logout,
};
